@tailwind base;
@tailwind components;
@tailwind utilities;


.scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }
  
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }